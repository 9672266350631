<template>
  <div>
    <div class="mb-1">
      <b-modal id="details-generator-maintenance-info-modal" title="Generator Maintenance Info Details" size="xl"
               hide-footer @hidden="onHidden">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-bordered text-center">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Running Hours</th>
                  <th>Staring Load</th>
                  <th>Running Load</th>
                  <th>Date of Last Servicing</th>
                  <th>List of Service Works</th>
                  <th>Next Service Date</th>
                  <th>Maintenance Cost</th>
                  <th>Service Image/File</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(generator_maintenance_info, index) in generator_maintenance_infos" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ generator_maintenance_info.running_hours }}</td>
                  <td>{{ generator_maintenance_info.starting_load }}</td>
                  <td>{{ generator_maintenance_info.running_load }}</td>
                  <td>{{ generator_maintenance_info.date_of_last_servicing }}</td>
                  <td>{{ generator_maintenance_info.list_of_service_works }}</td>
                  <td>{{ generator_maintenance_info.next_service_date }}</td>
                  <td>{{ generator_maintenance_info.maintenance_cost }}</td>
                  <td>
                     <span class="ant-badge">
                        <template v-if="generator_maintenance_info.service_image_or_file_extension == 'pdf'">
                          <a :href="generator_maintenance_info.service_image_or_file_url" target="_blank">
                            <div>
                              <img src="/pdf.png" alt="pdf" style="width: 45px; height: 45px">
                            </div>
                          </a>
                        </template>
                       <template v-else>
                         <a :href="generator_maintenance_info.service_image_or_file_url" target="_blank">
                           <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                             <img :src="generator_maintenance_info.service_image_or_file_url" width="1em" height="1em">
                           </span>
                         </a>
                       </template>
                  </span>
                  </td>
                  <td>
                    <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(generator_maintenance_info.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
                  </td>
                </tr>
                </tbody>
              </table>
              <h5 class="text-center text-secondary" v-if="generator_maintenance_infos.length === 0">
                <Empty :image="simpleImage"></Empty>
              </h5>
              <hr>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'

export default {
  name: 'maintenance-details',
  props: ['generator_maintenance_infos'],
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  methods: {
    onDelete(generatorMaintenanceInfoId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/generator-maintenance-info/' + generatorMaintenanceInfoId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('search')
            }
            this.$bvModal.hide('details-generator-maintenance-info-modal')
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    onHidden() {
      // this.generator_maintenance_infos = []
    },
  },
}
</script>

<style scoped>

</style>
