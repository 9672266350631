<template>
  <div v-if="Object.keys(view_generator_info).length !== 0" >
    <div class="mb-1">
      <b-modal id="view-generator-info-modal" title="View Generator Info" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>Date When Generator Was Supplied</th>
              <td>{{ view_generator_info.date_when_generator_was_supplied }}</td>
            </tr>
            <tr>
              <th>Generator Brands</th>
              <td>{{ view_generator_info.generator_brand }}</td>
            </tr>
            <tr>
              <th>Engine Brand</th>
              <td>{{ view_generator_info.engine_brand }}</td>
            </tr>
            <tr>
              <th>Asset Tag No.</th>
              <td>{{ view_generator_info.asset_tag_no }}</td>
            </tr>
            <tr>
              <th>Model No. </th>
              <td>{{view_generator_info.model_no }}</td>
            </tr>
            <tr>
              <th>Capacity Kva</th>
              <td>{{ view_generator_info.capacity_kva }}</td>
            </tr>
            <tr>
              <th>Output Kw</th>
              <td>{{ view_generator_info.output_kw }}</td>
            </tr>
            <tr>
              <th>Rpm</th>
              <td> {{ view_generator_info.rpm }}</td>
            </tr>
            <tr>
              <th>Ats</th>
              <td> {{ view_generator_info.ats }}</td>
            </tr>
            <tr>
              <th>Fuel Pressure Gauge</th>
              <td> {{ view_generator_info.fuel_pressure_gauge }}</td>
            </tr>
            <tr>
              <th>Air Pressure Gauge</th>
              <td> {{ view_generator_info.air_pressure_gauge }}</td>
            </tr>
            <tr>
              <th>Temperature Gauge</th>
              <td> {{ view_generator_info.temperature_gauge }}</td>
            </tr>
            <tr>
              <th>Fuel Indicator</th>
              <td> {{ view_generator_info.fuel_indicator }}</td>
            </tr>
            <tr>
              <th>Hub</th>
              <td> {{ view_generator_info.hub.name }}</td>
            </tr>
            </thead>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: 'viewGeneratorInfo',
  props: ['view_generator_info'],
  data() {
    return {}
  },
}
</script>
