<template>
 <div>
   <div class="card">
     <div class="card-body">
       <form action="">
         <div class="form-row">
           <div class="col-md-4">
             <select v-model="search_data.hub_id" v-validate="{ rules: { required:  user.role.includes('hub_in_charge') ? true : false }}" id="hub_id" name="hub_id" class="form-control form-control-sm">
               <option :value="''">Select a hub</option>
               <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
             </select>
             <div class="invalid-feedback">Hub is required</div>
           </div>
           <div class="col-md-4">
             <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
           </div>
         </div>
       </form>
     </div>
   </div>
   <div class="card">
     <div class="card-body">
       <div class="table-responsive">
         <table class="table table-sm table-bordered text-center">
           <thead>
           <tr>
             <th>#</th>
             <th>Hub</th>
             <th>Image</th>
             <th>Supplied Date</th>
             <th>Generator Brand</th>
             <th>Action</th>
           </tr>
           </thead>
           <tbody>
           <tr v-for="(generator_info, index) in generator_infos.data" :key="index">
             <td>{{ generator_infos.from + index }}</td>
             <td>{{ generator_info.hub.name }}</td>
             <td>
                 <span class="ant-badge">
                <a :href="generator_info.generator_image" target="_blank" download>
                   <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                        <img :src="generator_info.generator_image_url"  width="1em" height="1em">
                   </span>
                </a>
              </span>
             </td>
             <td>{{ generator_info.date_when_generator_was_supplied }}</td>
             <td>{{ generator_info.generator_brand }}</td>
             <td>
               <a class="btn btn-sm btn-info mr-2" href="javascript: void(0);" @click.prevent="view(generator_info), $bvModal.show('view-generator-info-modal')"><i class="fe fe-info mr-2"></i>View</a>
               <router-link :to="{ name: 'generatorInfoUpdate', params: { generator_info_id: generator_info.id } }" class="btn btn-sm btn-primary mr-2" tag="a">
                 <i class="fe fe-edit"/> Update
               </router-link>
               <a class="btn btn-sm btn-success mr-2" href="javascript: void(0);" @click.prevent="saveGeneratorMaintenanceInfo(generator_info.id)"><i class="fe fe-plus mr-2"></i>Add Maintenance Info</a>
               <a class="btn btn-sm btn-success mr-2" href="javascript: void(0);" @click.prevent="detailsGeneratorMaintenanceInfo(generator_info.generator_maintenance_infos)"><i class="fe fe-eye mr-2"></i>Details Maintenance Info</a>
               <router-link :to="{ name: 'generatorFuelConsumptionList', params: { generator_info_id: generator_info.id } }" class="btn btn-sm btn-primary mr-2" tag="a">
                 <i class="fe fe-info"/> Generator Fuel Consumption
               </router-link>
               <router-link :to="{ name: 'generatorFuelConsumptionReport', params: { generator_info_id: generator_info.id } }" class="btn btn-sm btn-success mr-2" tag="a">
                 <i class="fe fe-info"/> Generator Fuel Consumption Report
               </router-link>
             </td>
           </tr>
           </tbody>
         </table>
         <a-skeleton active :loading="loading"></a-skeleton>
         <h5 class="text-center text-secondary" v-if="generator_infos.total === 0 || !flag">
           <Empty :image="simpleImage"></Empty>
         </h5>
         <hr>
         <div class="float-right">
           <pagination class="mt-2" :data="generator_infos" :limit="2" @pagination-change-page="getResults"></pagination>
         </div>
       </div>
     </div>
   </div>
   <view-generator-info :view_generator_info="view_generator_info" ref="viewGeneratorInfo"></view-generator-info>
   <add-generator-maintenance-info :generator_info_id="generator_info_id" @search="search"></add-generator-maintenance-info>
   <details-generator-maintenance-info :generator_maintenance_infos="generator_maintenance_infos" @search="search"></details-generator-maintenance-info>
 </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'
import viewGeneratorInfo from '@/views/generator/generatorInfo/view'
import addGeneratorMaintenanceInfo from '@/views/generator/generatorMaintenanceInfo/add'
import detailsGeneratorMaintenanceInfo from '@/views/generator/generatorMaintenanceInfo/maintenance-details'

export default {
  name: 'create',
  components: { Empty, viewGeneratorInfo, addGeneratorMaintenanceInfo, detailsGeneratorMaintenanceInfo },
  data() {
    return {
      hubs: [],
      generator_infos: {},
      generator_maintenance_infos: [],
      btnLoading: false,
      loading: false,
      loader: false,
      flag: false,
      show: false,
      generator_info_id: '',
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      view_generator_info: {},
      search_data: {
        hub_id: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted: function () {
    this.getCodes()
  },
  methods: {
    getCodes() {
      apiClient.get('/api/generator-info/codes')
        .then(response => {
          this.hubs = response.data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/generator-info/list/search', this.search_data)
            .then(response => {
              this.flag = true
              this.loading = false
              this.btnLoading = false
              this.generator_infos = response.data.generator_infos
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.$validator.validate().then(valid => {
        if (valid) {
          apiClient.post('api/generator-info/list/search?page=' + page, this.search_data)
            .then(response => {
              this.generator_infos = response.data.generator_infos
            })
        }
      })
    },
    view(generatorInfo) {
      this.view_generator_info = {}
      this.view_generator_info = generatorInfo
    },
    saveGeneratorMaintenanceInfo(generatorInfoId) {
      this.$bvModal.show('add-generator-maintenance-info-modal')
      this.generator_info_id = generatorInfoId
    },
    detailsGeneratorMaintenanceInfo(generatorMaintenanceInfos) {
      this.$bvModal.show('details-generator-maintenance-info-modal')
      this.generator_maintenance_infos = generatorMaintenanceInfos
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>

</style>
