<template>
  <div>
    <div class="mb-1">
      <b-modal id="add-generator-maintenance-info-modal" title="Generator Maintenance Info Add" size="xl" hide-footer  @hidden="onHidden">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="" enctype="multipart/form-data" files="true" role="form">
          <div class="modal-content bg-white rounded">
            <div class="form-row">
              <div class="col-md-6">
                <label for="running_hours" class="col-form-label col-form-label-sm">Running Hours <span class="custom-required">*</span></label>
                <input type="text" v-model="running_hours" v-validate="{ rules: { required:  true }, max: 191 }" name="running_hours" id="running_hours" class="form-control form-control-sm" placeholder="Enter running hours">
                <div class="invalid-feedback">Running hours is required</div>
              </div>
              <div class="col-md-6">
                <label for="starting_load" class="col-form-label col-form-label-sm">Starting Load <span class="custom-required">*</span></label>
                <input type="text" v-model="starting_load" v-validate="{ rules: { required:  true }, max: 191 }" name="starting_load" id="starting_load" class="form-control form-control-sm" placeholder="Enter starting load">
                <div class="invalid-feedback">Starting load is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <label for="running_load" class="col-form-label col-form-label-sm">Running Load <span class="custom-required">*</span></label>
                <input type="text" v-model="running_load" v-validate="{ rules: { required:  true }, max: 191 }"  name="running_load" id="running_load" class="form-control form-control-sm" placeholder="Enter running load">
                <div class="invalid-feedback">Running load is required</div>
              </div>
              <div class="col-md-6">
                <label for="date_of_last_servicing" class="col-form-label col-form-label-sm">Date of Last Servicing <span class="custom-required">*</span></label>
                <datepicker :format="customLastServicingDateFormat" ref="customLastServicingDateFormat" v-model="date_of_last_servicing" :class="errors.has('date_of_last_servicing') ? 'error-border-color' : ''"  id="date_of_last_servicing"  name="date_of_last_servicing" placeholder="Select last serviceing date" ></datepicker>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('date_of_last_servicing')">Last servicing date is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <label for="list_of_service_works" class="col-form-label col-form-label-sm">List of Service Works <span class="custom-required">*</span></label>
                <textarea v-model="list_of_service_works" v-validate="{ rules: { required:  true }, max: 191 }"  name="list_of_service_works" id="list_of_service_works" class="form-control form-control-sm" placeholder="Enter list of service works"></textarea>
                <div class="invalid-feedback">List of service works is required</div>
              </div>
              <div class="col-md-6">
                <label for="service_image_or_file" class="col-form-label col-form-label-sm">Service Image or File <span class="custom-required">*</span></label>
                <input type="file" ref="serviceImageOrFileImage" v-validate="{ rules: { required:  true }}" id="service_image_or_file" name="service_image_or_file" @change="serviceImageOrFileImage()" accept=".jpg,.jpeg,.png,.pdf" class="form-control form-control-sm"/>
                <div class="invalid-feedback">Service image or file is required</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6">
                <label for="maintenance_cost" class="col-form-label col-form-label-sm">Maintenance Cost <span class="custom-required">*</span></label>
                <input type="number" v-model="maintenance_cost" v-validate="{ required: true, regex: /^[0-9]*$/, max: 8 }"  name="maintenance_cost" id="maintenance_cost" class="form-control form-control-sm" placeholder="Enter maintenance cost">
                <div class="invalid-feedback">Maintenance cost is required</div>
              </div>
              <div class="col-md-6">
                <label for="next_service_date" class="col-form-label col-form-label-sm">Next Service Date <span class="custom-required">*</span></label>
                <datepicker :format="customNextServiceDateFormat" ref="customNextServiceDateFormat" v-model="next_service_date" :class="errors.has('next_service_date') ? 'error-border-color' : ''"  id="next_service_date"  name="next_service_date" placeholder="Select next service date" ></datepicker>
                <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('next_service_date')">Next service date is required</div>
              </div>
            </div>
            <div class="form-row mt-3 mb-3">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <a-button type="primary" class="text-center mr-2" :loading="submitLoading"  @click.prevent="save">Add</a-button>
                <a-button type="danger" class="text-center" @click="$bvModal.hide('add-generator-maintenance-info-modal')">Cancel</a-button>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

export default {
  name: 'create',
  components: { Datepicker },
  props: ['generator_info_id'],
  data() {
    return {
      submitLoading: false,
      running_hours: '',
      starting_load: '',
      running_load: '',
      date_of_last_servicing: '',
      list_of_service_works: '',
      service_image_or_file: '',
      maintenance_cost: '',
      next_service_date: '',
      validation_errors: {},
      localValue: this.generator_info_id,
      show: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    generatorInfoId: {
      get() {
        return this.generator_info_id
      },
      set(localValue) {
        this.generator_info_id = localValue
      },
    },
  },
  mounted() {
    //
  },
  methods: {
    customLastServicingDateFormat(date) {
      const lastServicingDate = moment(date).format('YYYY-MM-DD')
      this.date_of_last_servicing = lastServicingDate
      return lastServicingDate
    },
    customServiceDateFormat(date) {
      const serviceDate = moment(date).format('YYYY-MM-DD')
      this.service_date = serviceDate
      return serviceDate
    },
    customNextServiceDateFormat(date) {
      const nextServiceDate = moment(date).format('YYYY-MM-DD')
      this.next_service_date = nextServiceDate
      return nextServiceDate
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.submitLoading = true
          const formData = new FormData()
          formData.append('running_hours', this.running_hours)
          formData.append('starting_load', this.starting_load)
          formData.append('running_load', this.running_load)
          formData.append('date_of_last_servicing', this.date_of_last_servicing)
          formData.append('list_of_service_works', this.list_of_service_works)
          formData.append('service_image_or_file', this.service_image_or_file)
          formData.append('maintenance_cost', this.maintenance_cost)
          formData.append('next_service_date', this.next_service_date)
          formData.append('generator_info_id', this.generatorInfoId)
          apiClient.post('/api/generator-maintenance-info/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.running_hours = ''
                this.starting_load = ''
                this.running_load = ''
                this.date_of_last_servicing = ''
                this.$refs.serviceImageOrFileImage.value = null
                this.list_of_service_works = ''
                this.service_image_or_file = ''
                this.maintenance_cost = ''
                this.next_service_date = ''
                this.$emit('search')
                this.$bvModal.hide('add-generator-maintenance-info-modal')
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    serviceImageOrFileImage() {
      this.service_image_or_file = this.$refs.serviceImageOrFileImage.files[0]
      if (this.service_image_or_file.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.service_image_or_file = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    onHidden () {
      // this.generator_info_id = ''
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
